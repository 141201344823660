%app-category-shared {
  margin: .8rem -.5rem;
  padding: 0;
  border: 0;
  outline: 0;  
  overflow: hidden;
  background-color: transparent;
  color: white;
  white-space: nowrap;   
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.app-category-fade {
  animation: fadein 1s;  
}

.app-categories {
  @extend %app-category-shared;  
}

.app-category {
  @extend %app-category-shared;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  &-flyout {
    color: white;
    overflow: hidden;
    max-width: 0;
    opacity: 0;
    transition: max-width 1s, opacity 1s, transform 1s ease-in-out;
    font-size: 1.1rem;

    span {
      padding-right: 1rem;
    }
  }

  &-label {
    font-size: 1.4rem;  
    font-weight: 600;
  }

  &-caret {
    color: white;
    line-height: 1.6rem;
    height: 1.6rem;
    font-size: 1.6rem;
    align-self: flex-end;
  }

  &:hover:not(:focus) {
    .app-category-label {
      opacity: .6;
      transition: 0.5s;
    }
  }

  &:focus {
    color: red;
  }

  &:hover,
  &:focus {
    .app-category-caret {
      transform: scale(1.2);
    }

    .app-category-flyout {
      max-width: 10rem;
      opacity: 1;
      transform: translate(1.1rem, 0);
    }
  }
}