.slider-item {
  padding: 0;
  margin: 0;
  display: inline-block;

  &-container {
    position: relative;
    border: .3rem solid #1d1d1d;
    cursor: pointer;
    border-radius: .8rem;
    overflow: hidden;
    margin: 2px;    

    &__selected {
      border-color: #e50914;  
    }

    @media (hover) {
      &:hover:not(&__selected) {
        opacity: .6;
        transition: .6s;
      }
    }

    img {
      // border-radius: .8rem;
      //image-rendering: pixelated;
      display: block;
      width: 100%;
      max-width: 100%;
      min-height: 5rem;
      overflow: hidden;
      -webkit-touch-callout: none;
    }  

    .img-hidden {
      opacity: 0;
    }

    .img-fade-in {
      opacity: 1 !important;
      transition: .2s  ease-in;
    }    

    .slider-item-title {
      position: absolute;
      display: block;
      color: white;
      bottom: 0;
      font-size: 1.4rem;
      width: 100%;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      background-color: #1d1d1d;
      padding: 5px;
      opacity: .8;

      &__selected {
        color: white;
      }
    }

    @media only screen and (min-width: 1000px) {
      .slider-item-title {
        font-size: 1.2rem;
      }
    }  

    @media only screen and (min-width: 1440px) {
      .slider-item-title {
        font-size: 1rem;
      }
    }  

    /* Mobile sizing (experimental) */
    @media only screen and (max-width: 950px) and (max-height: 400px) {
      .slider-item-title {
        font-size: 1.3rem;
      }
    }      
  }
}

