.slider {
  padding: 0 4%;
  position: relative;
  margin: 5px 0 5px 0;
  overflow: hidden;
  outline: none;

  &-content {
    white-space: nowrap;
  }

  .slider-control {
    background-color: rgba(20, 20, 20, 0.7);
  }

  @media (hover) {
    .slider-control {
      background-color: transparent;
    }  

    &:hover,
    &:active {
      .slider-control {
        background-color: rgba(20, 20, 20, 0.7);
        animation: fadein .5s;   
      }

      .slider-control-arrow {
        display: block;
      }
    }
  }

  &-no-items {
    height: 7rem; 
    line-height: 7rem;
    color: white;
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    text-align: center;
    vertical-align: middle;
  }
}

// animation: fadein 1s;    

// animation-name:slideup;
// animation-delay:0.5s;
// animation-duration:0.8s;
// animation-fill-mode:forwards;
// animation-timing-function:ease-out;


@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

// @keyframes slideup {
//   0%{bottom:-10vh}
//   100%{bottom:0;}
// }