%logo-shared {
  font-size: 1.7rem;  
  display: inline-block;
  margin: 0 auto;
  font-family: 'Orbitron';
  display: flex;
  align-items: center;
  color: #999;
  padding: .55rem;  
}

.logo {
  @extend %logo-shared;
  opacity: .8;

  &-left-text {
    margin-right: .05rem;    
  }

  &-right-text {
    margin-left: .05rem;    
  }

  &-image {    
    height: 1.85rem;     
    opacity: .7;
  }
}

.logoLarge {
  @extend %logo-shared;
  font-size: 6.7rem;  
  color: white;

  &-right-text {
    margin-left: .05rem;  
  }

  &-image {    
    height: 8rem;     
  }
}