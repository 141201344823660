html {
  background-color: #0b0b0b;
  -webkit-text-size-adjust: 100%;
}

body {
  position: fixed;
  display: block;
  background-color: #0b0b0b;
}

@media only screen and (max-width: 864px) {
  .webrcade-outer {
    max-height: 90vw;
  }
}

@media only screen and (max-width: 1152px) and (min-width: 864px) {
  .webrcade-outer {
    max-height: 75vw;
  }
}

.details-header-nav {
  display: none;
}

/* Mobile sizing (experimental) */
@media only screen and (max-width: 950px) and (max-height: 400px) {
  .header-nav {
    display:none;
  }

  .details-header-nav {
    display: block;
  }

  .app-details-content-container-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 2.7rem;
    padding-top: 0;
  }

  .app-details-content-container-description {
    min-height: 0px;
  }
}