  .scroll-container {
    // border: 1px solid blue;
    padding: 0;
    margin: 0;
    overflow: hidden;  
    flex-grow: 1;    
    // mask-image: -webkit-gradient(linear, left 80%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))
  }
  
  .scroll-text {    
    // border: 1px solid red;
    position: relative;
    padding: 0;
    margin: 0;
    -webkit-transition: opacity .9s ease-in-out;
    -moz-transition: opacity .9s ease-in-out;
    -ms-transition: opacity .9s ease-in-out;
    -o-transition: opacity .9s ease-in-out;
    opacity: 1;

    &--fade-out {
      opacity: 0 !important;
    }
  }

  .scroll-height {
    position: absolute;
    visibility: hidden;
    z-index: -100;
  }

  .scroll-block {
    margin-top: 1.5rem;
  }

