.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%);
  font-size: 2rem;
  animation: fadein 2s;
  z-index: 5;
  color: #e5e5e5;

  &-text {
    padding-top: 1rem;
    text-align: center;
    color: #bbb;
  }
}
